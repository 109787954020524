<template>
 <section class="py-24 relative">
    <div class="min-h-screen w-full max-w-10xl mx-auto px-4 md:px-8">

        <!-- Navigation de la barre latérale -->
        <div class="min-h-screen flex flex-col lg:flex-row">
            <div class="lg:w-1/4 sm:w-full bg-gray-100 p-4 rounded-lg shadow-md">
                <ul class="flex flex-col gap-6">
                    <li :class="{'text-indigo-600 font-semibold border-b-2 border-indigo-600': activeTab === 'orders'}">
                        <a href="#" @click="setActiveTab('orders')" class="cursor-pointer text-lg leading-8 hover:text-indigo-600">Mes Commandes</a>
                    </li>
                    <li :class="{'text-indigo-600 font-semibold border-b-2 border-indigo-600': activeTab === 'account'}">
                        <a href="#" @click="setActiveTab('account')" class="cursor-pointer text-lg leading-8 hover:text-indigo-600">Mon Compte</a>
                    </li>
                    <li :class="{'text-indigo-600 font-semibold border-b-2 border-indigo-600': activeTab === 'refunds'}">
                        <a href="#" @click="setActiveTab('refunds')" class="cursor-pointer text-lg leading-8 hover:text-indigo-600">Mes Remboursements</a>
                    </li>
                </ul>
            </div>

            <!-- Zone de contenu principal -->
            <div class="lg:w-3/4 sm:w-full mt-8 lg:mt-0">
                <div v-if="activeTab === 'orders'">
    <h3 class="font-semibold text-xl">Historique des Commandes</h3>
    <section class="py-12 relative">
        <div class="w-full max-w-7xl mx-auto px-4 md:px-8">
            <div class="sm:hidden bg-gray-100 px-8 py-4 rounded-lg flex justify-between">
    <a @click="filterOrders('En Cours')" :class="{'text-indigo-600': filter === 'En Cours'}" class="font-medium text-lg leading-8 cursor-pointer transition-all duration-500">
        En Cours
    </a>
    <a @click="filterOrders('Livrée')" :class="{'text-indigo-600': filter === 'Livrée'}" class="font-medium text-lg leading-8 cursor-pointer transition-all duration-500">
        Livrée
    </a>
    <a @click="filterOrders('Annulées')" :class="{'text-indigo-600': filter === 'Annulées'}" class="font-medium text-lg leading-8 cursor-pointer transition-all duration-500">
        Annulées
    </a>
</div>
<div class="flex flex-col lg:flex-row lg:items-center justify-between">
    <ul class="flex flex-col sm:flex-row sm:gap-x-14 gap-y-3 sm:items-center lg:flex hidden">
        <li @click="filterOrders('all')" :class="{'text-indigo-600': filter === 'all'}" class="font-medium text-lg leading-8 cursor-pointer transition-all duration-500">
            Toutes les Commandes
        </li>
        <li @click="filterOrders('En Cours')" :class="{'text-indigo-600': filter === 'En Cours'}" class="font-medium text-lg leading-8 cursor-pointer transition-all duration-500">
            En Cours
        </li>
        <li @click="filterOrders('Livrée')" :class="{'text-indigo-600': filter === 'Livrée'}" class="font-medium text-lg leading-8 cursor-pointer transition-all duration-500">
            Livrée
        </li>
        <li @click="filterOrders('Annulées')" :class="{'text-indigo-600': filter === 'Annulées'}" class="font-medium text-lg leading-8 cursor-pointer transition-all duration-500">
            Annulées
        </li>
    </ul>
</div>


            <!-- Tableau des commandes -->
            <div class="overflow-x-auto mx-auto p-6">
                <table class="min-w-full bg-white">
                    <thead>
                        <tr class="w-full border-b">
                            <th class="py-4 px-6 text-left text-gray-500">Produit</th>
                            <th class="py-4 px-6 text-left text-gray-500">Numéro de commande</th>
                            <th class="py-4 px-6 text-left text-gray-500">Statut</th>
                            <th class="py-4 px-6 text-left text-gray-500">Prix</th>
                            <th class="py-4 px-6 text-left text-gray-500"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(order, index) in filteredOrders" :key="index" class="border-b">
                            <td class="py-4 px-6 flex items-center">
                                <img :src="order.productImage" alt="Image du produit" class="w-16 h-16 rounded-md mr-4" />
                                <span class="text-gray-700">{{ order.productName }}</span>
                            </td>
                            <td class="py-4 px-6 text-gray-700">{{ order.orderNumber }}</td>
                            <td class="py-4 px-6">
                                <span :class="{
                                    'text-green-500': order.status === 'Livrée',
                                    'text-yellow-500': order.status === 'En Cours',
                                    'text-red-500': order.status === 'Annulées'
                                }">
                                    {{ order.status }}
                                </span>
                            </td>
                            <td class="py-4 px-6 text-gray-700">{{ order.price }} €</td>
                            <td class="py-4 px-6 text-gray-700">
                                <button @click="showOrderDetails(order)" class="text-blue-500">Voir Détails</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <div v-if="showDetails" class="fixed inset-0 bg-gray-500 bg-opacity-50 z-50" @click="closeDetails">
    <div class="transition-transform transform bg-white w-full h-full md:w-1/2 absolute top-0 right-0 overflow-y-auto" :class="{'translate-x-full': !showDetails, 'translate-x-0': showDetails}">
        <button @click="closeDetails" class="absolute top-4 right-4 text-gray-700">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </button>
        <section class="py-24 relative">
            <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                <div class="w-full flex-col justify-start items-start lg:gap-10 gap-8 inline-flex">
                    <h2 class="text-center text-black text-3xl font-bold font-manrope leading-normal">Suivi de commande</h2>
                    <div class="w-full flex-col justify-start items-start gap-3 flex">
                        <h3 class="text-gray-900 text-2xl font-semibold font-manrope leading-9">Détails de la commande :
                            <span class="font-medium" id="orderId">{{ selectedOrder ? selectedOrder.orderNumber : 'N/A' }}</span>
                        </h3>
                        <h4 class="text-gray-600 text-xl font-medium leading-loose" id="orderDate">Date : {{ selectedOrder ? selectedOrder.orderDate : 'N/A' }}</h4>
                    </div>
                    <div class="w-full flex-col justify-center sm:items-center items-start gap-8 flex">
    <ol class="flex flex-col sm:flex-row sm:items-center items-start w-full sm:gap-0 gap-3 relative">
        <!-- Étape 1 -->
        <li class="flex w-full relative justify-center text-indigo-600 text-base font-semibold after:content-[''] after:w-0.5 after:h-16 after:border after:bg-indigo-600 after:inline-block after:absolute sm:after:h-0.5 sm:after:w-full sm:after:top-5 sm:after:top-3 after:left-1/2 after:-translate-x-1/2">
            <div class="block sm:whitespace-nowrap z-10 flex flex-col items-center">
                <span class="w-6 h-6 bg-indigo-600 text-center border-2 border-transparent rounded-full flex justify-center items-center mx-auto mb-1 text-base font-bold text-white sm:w-10 sm:h-10">1</span>
                Commande confirmée <br>
                <span class="text-indigo-600 text-base font-normal text-center" id="orderConfirmedTime">{{ selectedOrder ? selectedOrder.orderDate : 'N/A' }}</span>
            </div>
        </li>
        <!-- Étape 2 -->
        <li class="flex w-full relative justify-center text-black text-base font-semibold after:content-[''] after:w-0.5 after:h-16 after:border after:bg-indigo-200 after:inline-block after:absolute sm:after:h-0.5 sm:after:w-full sm:after:top-5 sm:after:top-3 after:left-1/2 after:-translate-x-1/2">
            <div class="block sm:whitespace-nowrap z-10 flex flex-col items-center">
                <span class="w-6 h-6 bg-indigo-600 rounded-full flex justify-center items-center mx-auto mb-1 text-white text-base font-bold sm:w-10 sm:h-10">2</span>
                Expédition
                <span class="text-gray-500 text-base font-normal text-center" id="shippingDetails">{{ selectedOrder ? selectedOrder.shippingMethod : 'N/A' }}</span>
            </div>
        </li>
        <!-- Étape 3 -->
        <li class="flex w-full relative justify-center text-gray-500 text-base font-semibold after:content-[''] after:w-0.5 after:h-16 after:border after:bg-indigo-200 after:inline-block after:absolute sm:after:h-0.5 sm:after:w-full sm:after:top-5 sm:after:top-3 after:left-1/2 after:-translate-x-1/2">
            <div class="block sm:whitespace-nowrap z-10 flex flex-col items-center">
                <span class="w-6 h-6 text-indigo-600 border-2 bg-transparent border-indigo-600 rounded-full flex justify-center items-center mx-auto mb-1 text-sm sm:w-10 sm:h-10">3</span>
                À livrer
                <span class="text-gray-500 text-base font-normal text-center" id="deliveryDate">{{ selectedOrder ? 'Date estimée : ' + selectedOrder.deliveryDate : 'N/A' }}</span>
            </div>
        </li>
    </ol>
</div>



                    <div class="w-full flex-col justify-start items-start gap-8 flex">
                        <div class="w-full justify-start items-start lg:gap-8 gap-4 flex flex-col sm:flex-row">
                            <div class="w-full sm:w-1/2 flex-col justify-start items-start flex gap-1.5">
                                <label class="text-gray-600 text-base font-medium leading-relaxed">Nom du transporteur</label>
                                <input type="text" class="w-full focus:outline-none px-5 py-3 h-14 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200 text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed" placeholder="Engle Express" id="courierName" v-model="selectedOrder.courierName">
                            </div>
                            <div class="w-full sm:w-1/2 flex-col justify-start items-start flex gap-1.5">
                                <label class="text-gray-600 text-base font-medium leading-relaxed">Numéro de suivi</label>
                                <input type="text" class="w-full focus:outline-none px-5 py-3 h-14 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200 text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed" placeholder="#125410215452" id="trackingNumber" v-model="selectedOrder.trackingNumber">
                            </div>
                        </div>
                        <div class="w-full flex-col justify-start items-start flex gap-1.5">
                            <label class="text-gray-600 text-base font-medium leading-relaxed">URL de suivi de l'envoi</label>
                            <input type="text" class="w-full focus:outline-none px-5 py-3 h-14 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200 text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed" placeholder="https://www.shipmentlink.com/TDB1_CargoTracking.do" id="shipmentUrl" v-model="selectedOrder.shipmentUrl">
                        </div>
                    </div>

                    <div class="w-full flex-col justify-start items-start gap-7 flex">
                        <h3 class="text-indigo-600 text-2xl font-semibold font-manrope leading-9">Article commandé</h3>
                        <div class="w-full justify-start items-start flex flex-col" id="orderedItems">
                            <div v-for="(product, index) in selectedOrder.products" :key="index" class="flex items-center justify-between mt-4">
                                <img :src="product.productImage" alt="Image du produit" class="w-24 h-24 object-cover rounded-lg">
                                <div class="flex flex-col ml-4">
                                    <span class="font-medium text-lg">{{ product.productName }}</span>
                                    <span class="text-gray-600">{{ product.quantity }} x €{{ product.price.toFixed(2) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


</div>

                <div v-if="activeTab === 'account'">
  <section class="relative pt-36 pb-24 mx-10 ">
    <img src="https://pagedone.io/asset/uploads/1705471739.png" alt="cover-image" class="w-full absolute top-0 left-0 z-0 h-60 object-cover rounded-lg ">
    <div class="w-full max-w-7xl mx-auto px-6 md:px-8">
      <div class="flex items-center justify-center relative z-10 mb-2.5">
        <img :src="user.avatar" alt="user-avatar-image" class="border-4 border-solid border-white rounded-full object-cover">
      </div>

      <h3 class="text-center font-manrope font-bold text-3xl leading-10 text-gray-900 mb-3">{{ user.name }}</h3>

      <section class="py-4 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div class="lg:my-14 my-8 grid lg:grid-cols-2 grid-cols-1 gap-8">
            <!-- Informations de base -->
            <div class="w-full flex-col justify-start items-start gap-6 inline-flex">
              <h4 class="text-gray-900 text-xl font-semibold leading-8">Informations de base</h4>
              <div class="w-full flex-col justify-start items-start gap-8 flex">
                <!-- Champ Email -->
                <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                  <label class="flex gap-1 items-center text-gray-600 text-base font-medium leading-relaxed">
                    Email
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                      <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444"/>
                    </svg>
                  </label>
                  <input type="email" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200" v-model="user.email" placeholder="exemple@mail.com">
                </div>

                <!-- Champs Prénom et Nom -->
                <div class="w-full justify-start items-start gap-7 flex sm:flex-row flex-col">
                  <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                    <label class="text-gray-600 text-base font-medium leading-relaxed">Prénom</label>
                    <input type="text" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal px-5 py-3 rounded-lg border border-gray-200" v-model="user.firstName" placeholder="Jean">
                  </div>
                  <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                    <label class="text-gray-600 text-base font-medium leading-relaxed">Nom</label>
                    <input type="text" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal px-5 py-3 rounded-lg border border-gray-200" v-model="user.lastName" placeholder="Dupont">
                  </div>
                </div>

                <!-- Champ Téléphone -->
                <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                  <label class="text-gray-600 text-base font-medium leading-relaxed">Téléphone</label>
                  <input type="tel" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal px-5 py-3 rounded-lg border border-gray-200" v-model="user.phone" placeholder="+33 6 12 34 56 78">
                </div>
              </div>
            </div>

            <!-- Informations sur l'adresse -->
            <div class="w-full flex-col justify-start items-start gap-6 inline-flex">
              <h4 class="text-gray-900 text-xl font-semibold leading-8">Informations sur l'adresse</h4>
              <div class="w-full flex-col justify-start items-start gap-8 flex">
                <!-- Champ Adresse -->
                <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                  <label class="text-gray-600 text-base font-medium leading-relaxed">Adresse</label>
                  <input type="text" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal px-5 py-3 rounded-lg border border-gray-200" v-model="user.address" placeholder="123 Rue de Paris">
                </div>

                <!-- Champs Ville et Code Postal -->
                <div class="w-full justify-start items-start gap-7 flex sm:flex-row flex-col">
                  <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                    <label class="text-gray-600 text-base font-medium leading-relaxed">Ville</label>
                    <input type="text" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal px-5 py-3 rounded-lg border border-gray-200" v-model="user.city" placeholder="Paris">
                  </div>
                  <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                    <label class="text-gray-600 text-base font-medium leading-relaxed">Code Postal</label>
                    <input type="text" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal px-5 py-3 rounded-lg border border-gray-200" v-model="user.zipCode" placeholder="75001">
                  </div>
                </div>

                <!-- Champ Pays -->
                <div class="w-full flex-col justify-start items-start gap-1.5 flex">
                  <label class="text-gray-600 text-base font-medium leading-relaxed">Pays</label>
                  <input type="text" class="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal px-5 py-3 rounded-lg border border-gray-200" v-model="user.country" placeholder="France">
                </div>
              </div>
            </div>
          </div>

          <button @click="saveProfile" class="mx-auto sm:w-fit w-full px-7 py-3 bg-indigo-600 hover:bg-indigo-700 transition-all duration-700 ease-in-out rounded-xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex">
            <span class="px-2 text-center text-white text-lg font-semibold leading-8">Sauvegarder</span>
          </button>
        </div>
      </section>
    </div>
  </section>
</div>
 <div v-if="activeTab === 'refunds' ">
    <section class="py-4 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
            <div class="w-full flex-col justify-start items-start lg:gap-14 gap-10 inline-flex">
                <div class="w-full justify-between items-center gap-8 flex lg:flex-row flex-col">
                    <h2 class="w-full text-gray-900 text-3xl font-bold font-manrope leading-normal">Mes Remboursements</h2>
                    <div class="w-full justify-end items-center gap-5 flex sm:flex-row flex-col">
                        <div class="relative lg:w-64 w-full ">
                            <select id="countries" class="focus:outline-none px-4 py-2.5 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] placeholder-gray-400 text-gray-900 text-base font-normal leading-relaxed border border-gray-300 justify-start items-center gap-2 inline-flex
                            h-12 lg:w-64 w-full ">
                                <option value="1" selected>All Orders</option>
                                <option value="2">Selected Orders</option>
                                <option value="3">Customized Orders</option>
                                <option value="4">Other Orders</option>
                            </select>
                        </div>
                        <button
                            class="lg:w-fit w-full px-5 py-2.5 bg-indigo-600 hover:bg-indigo-800 transition-all duration-700 ease-in-out rounded-xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex">
                            <span
                                class="px-2 py-px text-white text-base font-semibold leading-relaxed whitespace-nowrap">Add
                                Refund Request</span>
                        </button>
                    </div>
                </div>
                <div class="overflow-x-auto w-full">
                    <table class="w-full rounded-lg mb-5">

                        <tbody class="">
                            <tr class="bg-white">
                                <td class="pb-5 pr-8 flex items-center lg:gap-8 gap-3.5">
                                    <img class="rounded-xl object-cover" src="https://pagedone.io/asset/uploads/1718791379.png"
                                        alt="Long Lasting Perfume image">
                                    <div class="flex flex-col gap-1">
                                        <h5 class="text-gray-500 text-lg font-medium leading-relaxed">#2012455</h5>
                                        <h5 class="text-gray-900 text-lg font-semibold leading-relaxed">Long Lasting
                                            Perfume</h5>
                                    </div>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-500 text-base font-medium leading-relaxed">
                                    06 Jun, 2024 <br>
                                    <span class="text-gray-900">At 02:17 PM</span>
                                </td>
                                <td
                                    class="p-8 whitespace-nowrap text-gray-900 text-base font-semibold leading-relaxed ">
                                    Reason:
                                    <span class="text-gray-500 font-medium">Defective Product</span>
                                </td>
                                <td class="p-8">
                                    <div
                                        class="w-fit mx-auto pl-2 pr-2.5 py-0.5 bg-indigo-50 rounded-full justify-center items-center gap-1 flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12"
                                            viewBox="0 0 13 12" fill="none">
                                            <path
                                                d="M6.12428 5.49991V7.49991M3.46751 1.7063L3.38331 1.73815C2.93176 1.90893 2.70599 1.99432 2.50861 2.12922C2.31124 2.26411 2.14967 2.44345 1.82654 2.80212L1.76562 2.86973M8.62382 1.7063L8.70802 1.73815C9.15957 1.90893 9.38534 1.99432 9.58271 2.12922C9.78009 2.26411 9.94166 2.44345 10.2648 2.80212L10.3257 2.86973M6.12428 10.4999C5.65986 10.4999 5.42765 10.4999 5.2327 10.4742C3.8865 10.297 2.82717 9.23769 2.64994 7.89149C2.62428 7.69655 2.62428 7.46433 2.62428 6.99991V6.49991C2.62428 6.03549 2.62428 5.80328 2.64994 5.60834C2.82717 4.26214 3.8865 3.20281 5.2327 3.02558C5.42765 2.99991 5.65986 2.99991 6.12428 2.99991C6.5887 2.99991 6.82091 2.99991 7.01586 3.02558C8.36206 3.20281 9.42138 4.26214 9.59861 5.60834C9.62428 5.80328 9.62428 6.03549 9.62428 6.49991V6.99991C9.62428 7.46433 9.62428 7.69655 9.59861 7.89149C9.42138 9.23769 8.36206 10.297 7.01586 10.4742C6.82091 10.4999 6.5887 10.4999 6.12428 10.4999Z"
                                                stroke="#4F46E5" stroke-linecap="round" />
                                        </svg>
                                        <span
                                            class="text-center text-indigo-600 text-xs font-medium leading-normal">Ongoing</span>
                                    </div>
                                </td>
                                <td class="py-10 px-2.5">
                                    <button
                                        class="p-2 hover:bg-gray-100 transition-all duration-700 ease-in-out group flex item-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path d="M12 16.9896V17.0396M12 11.976V12.026M12 6.96228V7.01228"
                                                stroke="black" stroke-width="2.5" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="pb-5 pr-8 flex items-center lg:gap-8 gap-3.5">
                                    <img class="rounded-xl object-cover" src="https://pagedone.io/asset/uploads/1718791411.png"
                                        alt="Long Lasting Perfume image">
                                    <div class="w-60 flex flex-col gap-1">
                                        <h5 class="text-gray-500 text-lg font-medium leading-relaxed">#2012455</h5>
                                        <div class="text-gray-900 text-lg font-semibold leading-relaxed">Long Lasting
                                            Perfume</div>
                                    </div>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-500 text-base font-medium leading-relaxed">
                                    24 May, 2024 <br>
                                    <span class="text-gray-900">At 04:20 PM</span>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-900 text-base font-semibold leading-relaxed">
                                    Reason:
                                    <span class="text-gray-500 font-medium">Quality Issue</span>
                                </td>
                                <td class="p-8 whitespace-nowrap ">
                                    <div
                                        class="w-fit mx-auto pl-2 pr-2.5 py-0.5 bg-emerald-50 rounded-full justify-center items-center gap-1 flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12"
                                            viewBox="0 0 19 12" fill="none">
                                            <path
                                                d="M1.62451 5.87114L5.7679 10.0145C6.43457 10.6812 6.7679 11.0145 7.18211 11.0145C7.59633 11.0145 7.92966 10.6812 8.59633 10.0145L17.6255 0.985352"
                                                stroke="#059669" stroke-width="1.6" stroke-linecap="round" />
                                        </svg>
                                        <span
                                            class="text-center text-emerald-600  text-xs font-medium leading-normal">Completed</span>
                                    </div>
                                </td>
                                <td class="py-10 px-2.5">
                                    <button
                                        class="p-2 hover:bg-gray-100 transition-all duration-700 ease-in-out group flex item-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path d="M12 16.9896V17.0396M12 11.976V12.026M12 6.96228V7.01228"
                                                stroke="black" stroke-width="2.5" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="pb-5 pr-8 flex items-center lg:gap-8 gap-3.5">
                                    <img class="rounded-xl object-cover" src="https://pagedone.io/asset/uploads/1718791426.png"
                                        alt="Natural Rose Face Pack image">
                                    <div class="flex flex-col gap-1">
                                        <h5 class="text-gray-500 text-lg font-medium leading-relaxed">#2012455</h5>
                                        <div class="text-gray-900 text-lg font-semibold leading-relaxed">Natural Rose
                                            Face Pack</div>
                                    </div>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-500 text-base font-medium leading-relaxed">
                                    21 May, 2024 <br>
                                    <span class="text-gray-900">At 12:25 PM</span>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-900 text-base font-semibold leading-relaxed">
                                    Reason:
                                    <span class="text-gray-500 font-medium">Incorrect Product</span>
                                </td>
                                <td class="p-8 whitespace-nowrap ">
                                    <div
                                        class="w-fit mx-auto pl-2 pr-2.5 py-0.5 bg-red-50 rounded-full justify-center items-center gap-1 flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12"
                                            viewBox="0 0 13 12" fill="none">
                                            <path d="M3.125 3L9.125 9M9.125 3L3.125 9" stroke="#DC2626"
                                                stroke-linecap="round" />
                                        </svg>
                                        <span
                                            class="text-center text-red-600 text-xs font-medium leading-normal">Failed</span>
                                    </div>
                                </td>
                                <td class="py-10 px-2.5">
                                    <button
                                        class="p-2 hover:bg-gray-100 transition-all duration-700 ease-in-out group flex item-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path d="M12 16.9896V17.0396M12 11.976V12.026M12 6.96228V7.01228"
                                                stroke="black" stroke-width="2.5" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="pb-5 pr-8 flex items-center lg:gap-8 gap-3.5">
                                    <img class="rounded-xl object-cover" src="https://pagedone.io/asset/uploads/1718791449.png"
                                        alt="Givenchy Gift Set of 3 image">
                                    <div class="flex flex-col gap-1">
                                        <h5 class="text-gray-500 text-lg font-medium leading-relaxed">#2012455</h5>
                                        <div class="text-gray-900 text-lg font-semibold leading-relaxed">Givenchy Gift
                                            Set of 3</div>
                                    </div>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-500 text-base font-medium leading-relaxed">
                                    06 May, 2024 <br>
                                    <span class="text-gray-900">At 10:16 PM</span>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-900 text-base font-semibold leading-relaxed">
                                    Reason:
                                    <span class="text-gray-500 font-medium">Defective Product</span>
                                </td>
                                <td class="p-8 whitespace-nowrap ">
                                    <div
                                        class="w-fit mx-auto pl-2 pr-2.5 py-0.5 bg-emerald-50 rounded-full justify-center items-center gap-1 flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12"
                                            viewBox="0 0 19 12" fill="none">
                                            <path
                                                d="M1.62451 5.87114L5.7679 10.0145C6.43457 10.6812 6.7679 11.0145 7.18211 11.0145C7.59633 11.0145 7.92966 10.6812 8.59633 10.0145L17.6255 0.985352"
                                                stroke="#059669" stroke-width="1.6" stroke-linecap="round" />
                                        </svg>
                                        <span
                                            class="text-center text-emerald-600 text-xs font-medium leading-normal">Completed</span>
                                    </div>
                                </td>
                                <td class="py-10 px-2.5">
                                    <button
                                        class="p-2 hover:bg-gray-100 transition-all duration-700 ease-in-out group flex item-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path d="M12 16.9896V17.0396M12 11.976V12.026M12 6.96228V7.01228"
                                                stroke="black" stroke-width="2.5" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="pr-8 flex items-center lg:gap-8 gap-3.5">
                                    <img class="rounded-xl object-cover" src="https://pagedone.io/asset/uploads/1718791464.png"
                                        alt="Canoino Blue Silver Watch image">
                                    <div class="flex flex-col gap-1">
                                        <h5 class="text-gray-500 text-lg font-medium leading-relaxed">#2012455</h5>
                                        <div class="text-gray-900 text-lg font-semibold leading-relaxed">Canoino Blue
                                            Silver Watch</div>
                                    </div>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-500 text-base font-medium leading-relaxed">
                                    20 May, 2024 <br>
                                    <span class="text-gray-900">At 11:17 PM</span>
                                </td>
                                <td class="p-8 whitespace-nowrap text-gray-900 text-base font-semibold leading-relaxed">
                                    Reason:
                                    <span class="text-gray-500 font-medium">Quality Issue</span>
                                </td>
                                <td class="p-8 whitespace-nowrap ">
                                    <div
                                        class="w-fit mx-auto pl-2 pr-2.5 py-0.5 bg-emerald-50 rounded-full justify-center items-center gap-1 flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12"
                                            viewBox="0 0 19 12" fill="none">
                                            <path
                                                d="M1.62451 5.87114L5.7679 10.0145C6.43457 10.6812 6.7679 11.0145 7.18211 11.0145C7.59633 11.0145 7.92966 10.6812 8.59633 10.0145L17.6255 0.985352"
                                                stroke="#059669" stroke-width="1.6" stroke-linecap="round" />
                                        </svg>
                                        <span
                                            class="text-center text-emerald-600 text-xs font-medium leading-normal">Completed</span>
                                    </div>
                                </td>
                                <td class="py-10 px-2.5">
                                    <button
                                        class="p-2 hover:bg-gray-100 transition-all duration-700 ease-in-out group flex item-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path d="M12 16.9896V17.0396M12 11.976V12.026M12 6.96228V7.01228"
                                                stroke="black" stroke-width="2.5" stroke-linecap="round" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <nav class="w-full flex items-center justify-center" aria-label="Table navigation">

                    <ul class="flex items-center justify-center text-sm h-12 md:gap-12 gap-2">
                        <li>
                            <a href="javascript:;"
                                class="flex items-center justify-center gap-2 sm:px-3 h-8 ml-0 text-gray-500 bg-white font-medium text-base leading-7  hover:text-gray-700 ">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0002 14.9999L8 9.99967L13.0032 4.99652" stroke="black"
                                        stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                Back
                            </a>
                        </li>
                        <li>
                            <ul class="flex items-center justify-center md:gap-4 gap-1">
                                <li>
                                    <a href="javascript:;"
                                        class="font-normal text-base leading-7 text-gray-500 py-2 px-4 rounded-full bg-white transition-all duration-700 ease-in-out hover:text-indigo-600">1</a>
                                </li>
                                <li>
                                    <a href="javascript:;"
                                        class="font-normal text-base leading-7 text-gray-500 py-2 px-4 rounded-full bg-white transition-all duration-700 ease-in-out hover:text-indigo-600">2</a>
                                </li>
                                <li>
                                    <a href="javascript:;"
                                        class="font-normal text-base leading-7 text-gray-500 py-2 px-4 rounded-full bg-white transition-all duration-700 ease-in-out hover:text-indigo-600">3</a>
                                </li>
                                <li>
                                    <a href="javascript:;"
                                        class="font-normal text-base leading-7 text-gray-500 py-2 px-4 rounded-full bg-white transition-all duration-700 ease-in-out hover:text-indigo-600">4</a>
                                </li>
                                <li>
                                    <a href="javascript:;"
                                        class="font-normal text-base leading-7 text-gray-500 py-2 px-4 rounded-full bg-white transition-all duration-700 ease-in-out hover:text-indigo-600">5</a>
                                </li>
                                <li>
                                    <a href="javascript:;"
                                        class="font-normal text-base leading-7 text-gray-500 py-2.5 px-4 rounded-full ">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.52754 10.001H5.47754M10.5412 10.001H10.4912M15.5549 10.001H15.5049"
                                                stroke="black" stroke-width="2.5" stroke-linecap="round"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;"
                                        class="font-normal text-base leading-7 text-gray-500 py-2 px-4 rounded-full bg-white transition-all duration-700 ease-in-out hover:text-indigo-600">10</a>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="javascript:;"
                                class="flex items-center justify-center gap-2 sm:px-3 h-8 ml-0 text-gray-500 bg-white font-medium text-base leading-7  hover:text-gray-700 ">
                                next
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00295 4.99646L13.0032 9.99666L8 14.9998" stroke="black"
                                        stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>

                            </a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </section>
 </div>

            </div>
        </div>
    </div>
</section>

</template>

<script>
export default {
  data() {
    return {
        filter: 'all', 
      activeTab: 'orders',
      showDetails: false,
      selectedOrder: null,
      user: {
      avatar: 'https://pagedone.io/asset/uploads/1705471668.png',
      name: 'Jenny Wilson',
      email: 'jenny@example.com',
      firstName: 'Jenny',
      lastName: 'Wilson',
      phone: '+33 6 12 34 56 78',
      address: '123 Rue de Paris',
      city: 'Paris',
      zipCode: '75001',
      country: 'France'
    },
    orders: [
        {
          orderNumber: '123456',
          status: 'En Cours',
          orderDate: '2024-11-20',
          deliveryAddress: '456 Avenue de la Liberté, 75002 Paris',
          shippingMethod: 'Livraison Standard',
          products: [
            {
              productName: 'Chaise Gaming',
              productImage: 'https://via.placeholder.com/100',
              quantity: 1,
              price: 99.99,
            },
            {
              productName: 'Clavier RGB',
              productImage: 'https://via.placeholder.com/100',
              quantity: 1,
              price: 49.99,
            }
          ]
        },
        {
          orderNumber: '789012',
          status: 'Annulée',
          orderDate: '2024-11-19',
          deliveryAddress: '123 Rue de Paris, 75001 Paris',
          shippingMethod: 'Retrait en magasin',
          products: [
            {
              productName: 'Lampe de Bureau',
              productImage: 'https://via.placeholder.com/100',
              quantity: 2,
              price: 45.00,
            }
          ]
        },
        {
          orderNumber: '789013',
          status: 'Livrée',
          orderDate: '2024-11-18',
          deliveryAddress: '123 Rue de Paris, 75001 Paris',
          shippingMethod: 'Livraison Express',
          products: [
            {
              productName: 'Lampe de Bureau',
              productImage: 'https://via.placeholder.com/100',
              quantity: 1,
              price: 45.00,
            },
            {
              productName: 'Support d\'ordinateur',
              productImage: 'https://via.placeholder.com/100',
              quantity: 1,
              price: 29.99,
            }
          ]
        },
        // Ajouter plus de commandes ici
      ],
    };
  },
  computed: {
    filteredOrders() {
        if (this.filter === 'all') {
            return this.orders;
        }
        return this.orders.filter(order => order.status === this.filter);
    }
},
methods: {
    filterOrders(status) {
        this.filter = status;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    viewOrderDetails(order) {
      alert(`Détails de la commande: ${order.orderNumber}`);
    },
    showOrderDetails(order) {
        this.selectedOrder = order;
        this.showDetails = true;
    },
    closeDetails() {
        this.showDetails = false;
        this.selectedOrder = null;
    }
  }
};
</script>




<style scoped>
.transition-transform {
    transition: transform 0.3s ease-in-out;
}

.translate-x-full {
    transform: translateX(100%);
}

.translate-x-0 {
    transform: translateX(0);
}

    .active-tab {
        color: #4F46E5; /* Indigo color for active tab */
        font-weight: 600;
        border-bottom: 2px solid #4F46E5;
    }
</style>
