<template>
    <div class="flex flex-col md:flex-row h-full md:h-screen">
      <!-- Section Formulaire -->
      <div class="w-full md:w-1/2 bg-white flex flex-col justify-center items-center p-6 md:p-10">
        <h1 class="text-3xl md:text-4xl font-semibold mb-2">
          {{ isSignUp ? 'Créer un compte' : 'Se connecter' }}
        </h1>
        <p v-if="isSignUp" class="text-gray-600 mb-6 md:mb-8 text-center md:text-left">
          Inscrivez-vous pour 
          commencer votre exploration d'idées de mobilier
        </p>
        <p v-else class="text-gray-600 mb-6 md:mb-8 text-center md:text-left">
          Bienvenue ! Veuillez vous connecter à votre compte.
        </p>
  
        <form
          class="w-full max-w-xs md:max-w-sm"
          @submit.prevent="isSignUp ? createAccount() : loginAccount()"
        >
          <!-- Champ Nom (Visible seulement lors de l'inscription) -->
          <div v-if="isSignUp" class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
              Nom
            </label>
            <input
              v-model="name"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Entrez votre nom"
            />
          </div>
  
          <!-- Champ Email -->
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
              Email
            </label>
            <input
              v-model="email"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Entrez votre email"
            />
          </div>
  
          <!-- Champ Mot de passe -->
          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
              Mot de passe
            </label>
            <input
              v-model="password"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Entrez votre mot de passe"
            />
          </div>
  
          <div class="flex items-center justify-between">
            <button
              class="bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="submit"
            >
              {{ isSignUp ? 'Créer un compte' : 'Se connecter' }}
            </button>
          </div>
        </form>
  
        <p class="text-gray-600 mt-4">
  {{ isSignUp ? 'Vous avez déjà un compte ?' : "Vous n'avez pas de compte ?" }}
  <br class="block md:hidden" /> <!-- Saut de ligne visible uniquement sur mobile -->
  <a
    class="text-red-500 cursor-pointer"
    @click="toggleForm"
  >
    {{ isSignUp ? 'Connectez-vous ici' : 'Inscrivez-vous ici' }}
  </a>
</p>

      </div>
  
      <!-- Section Image, cachée sur les téléphones -->
      <div class="hidden md:flex w-1/2 bg-gray-100 justify-center items-center">
        <img
          alt="Un lit moderne avec un cadre rembourré unique et des draps blancs"
          src="https://storage.googleapis.com/a1aa/image/jJAolbSPaDrYPdi4Jd0fRSX52NRsemDT3knhseZenihP7z7OB.jpg"
          class="w-full h-full object-cover"
        />
      </div>
    </div>
  </template>
  
  
  <script setup>
  import { ref } from 'vue';
  
  // Déclaration des variables réactives pour les données du formulaire
  const isSignUp = ref(true); // Variable pour gérer l'état du formulaire (inscription ou connexion)
  const name = ref('');
  const email = ref('');
  const password = ref('');
  
  // Fonction pour créer un compte
  const createAccount = () => {
    console.log('Account Created:', { name: name.value, email: email.value });
    alert('Account created successfully!');
  };
  
  // Fonction pour se connecter
  const loginAccount = () => {
    console.log('User Logged In:', { email: email.value, password: password.value });
    alert('Logged in successfully!');
  };
  
  // Fonction pour changer de formulaire
  const toggleForm = () => {
    isSignUp.value = !isSignUp.value;
  };
  </script>
  
  <style>
  body {
    font-family: 'Inter', sans-serif;
  }
  </style>
  