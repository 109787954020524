<template>
    <div class="p-8 mt-20 min-h-screen font-roboto">
      <!-- Informations sur la recherche -->

  
      <div class="flex flex-wrap md:flex-nowrap">
        <!-- Section des filtres -->
        <div class="w-full md:w-1/4 mb-8 md:mb-0">
          <!-- Filtre par catégorie -->
          <div class="mb-4 p-4 border rounded">
            <div class="font-bold mb-2">Catégorie</div>
            <div class="text-gray-700">Canapés</div>
            <div class="text-gray-700">Chaises</div>
            <div class="text-gray-700">Tables</div>
            <div class="text-gray-700">Bureaux</div>
            <div class="text-gray-700">Bibliothèques</div>
            <div class="text-gray-700">Lits</div>
          </div>
  
          <!-- Filtre par prix -->
          <div class="mb-4 p-4 border rounded">
            <div class="font-bold">Prix</div>
            <input type="range" min="0" max="5000" class="w-full mt-2" />
          </div>
  
          <!-- Filtre par taille -->
          <div class="p-4 border rounded">
            <div class="font-bold mb-2">Dimensions</div>
            <div class="flex flex-wrap gap-2">
              <div class="px-4 py-2 border rounded">Petit</div>
              <div class="px-4 py-2 border rounded">Moyenne</div>
              <div class="px-4 py-2 border rounded">Grand</div>
            </div>
          </div>
  
          <!-- Filtre par matériau -->
          <div class="mb-4 p-4 border rounded">
            <div class="font-bold mb-2">Matériau</div>
            <div class="text-gray-700">Bois</div>
            <div class="text-gray-700">Métal</div>
            <div class="text-gray-700">Verre</div>
            <div class="text-gray-700">Tissu</div>
          </div>
  
          <!-- Filtre par couleur -->
          <div class="p-4 border rounded">
            <div class="font-bold mb-2">Couleur</div>
            <div class="text-gray-700">Blanc</div>
            <div class="text-gray-700">Noir</div>
            <div class="text-gray-700">Gris</div>
            <div class="text-gray-700">Chêne</div>
          </div>
        </div>
  
        <!-- Section des produits -->
        <div class="w-full md:w-3/4 pl-0 md:pl-8">
          <!-- Tri et boutons de vue -->
          <div class="flex justify-between items-center mb-4">
            <span class="text-gray-500">Résultat de la recherche pour :</span> <span class="font-bold">{{ searchQuery }}</span>
  
            <!-- Boutons pour changer la vue -->
            <div class="flex items-center">
              <label for="sort" class="mr-2">Trier par :</label>
              <select id="sort" class="border rounded px-2 py-1">
                <option>Popularité</option>
                <option>Prix</option>
                <option>Nouvelle Arrivée</option>
              </select>
            </div>
          </div>
  
          <!-- Cartes de produits -->
    
          <div v-if="filteredProducts.length" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
  <div v-for="(product, index) in filteredProducts" :key="index" class="relative group bg-white rounded-lg shadow-md">
    <!-- Lien vers la page de détails du produit -->
    <router-link :to="{ name: 'product-details', params: { id: product.id } }" class="block">
      <!-- Image du produit -->
      <img style="border-radius: 10px 10px 0px 0px;" :alt="product.name" class="w-full object-cover" :src="product.image" :width="150" :height="150" />
    
      <!-- Volet avec bouton, initialement caché -->
      <div class="absolute bottom-0 left-0 w-full bg-black h-10 transform translate-y-full group-hover:translate-y-0 group-hover:opacity-100 opacity-0 transition-all duration-300 flex items-center justify-center text-white font-semibold rounded-b-lg">
        Ajouter au panier
      </div>
    </router-link>
    
    <!-- Détails du produit -->
    <div class="mt-4 p-4">
      <h3 class="text-sm text-left font-medium text-gray-500">{{ product.brand }}</h3>
      <p class="text-md text-left font-semibold text-gray-900">{{ product.name }}</p>
      <div class="flex items-center mt-2">
        <div class="flex text-yellow-500">
          <i v-for="star in 5" :key="star" class="fas fa-star"></i>
        </div>
        <p class="ml-2 text-sm text-gray-500">({{ product.reviewsCount }})</p>
      </div>
      <p class="mt-2 text-lg font-semibold text-gray-900">${{ product.price }}</p>
    </div>
  </div>
</div>

          <!-- Aucun produit trouvé -->
          <div v-else>
            <p class="text-gray-500">Aucun produit trouvé.</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SearchResultPage',
    data() {
      return {
        searchQuery: this.$route.query.query || '',
        products: [
          {
            id: 1,
            brand: "DOVE",
            name: "Body Wash with Pump with Skin Natural",
            image: "https://storage.googleapis.com/a1aa/image/Izoxbzte6qTnCqTHczGPte1utNJayvQ0yBosd3QP3DEfeBJPB.jpg",
            reviewsCount: 431,
            price: 19.93
          },
          {
            id: 2,
            brand: "CERAVE",
            name: "Cerave Hyaluronic Acid Serum for Face",
            image: "https://storage.googleapis.com/a1aa/image/kQwUNzrbUx5IFZF8NEvoCTtDPgVaEtTXzOLEfREVczqwPI5JA.jpg",
            reviewsCount: 114,
            price: 7.49
          },
          {
            id: 3,
            brand: "NEWYORKBIOLOGY",
            name: "New York Biology Dead Sea Mud Mask for Face",
            image: "https://storage.googleapis.com/a1aa/image/Y5FYojz4A5IPPR61r4yyJxoZQCKJ6PIuYYoBASTucLg4Hk8E.jpg",
            reviewsCount: 249,
            price: 11.99
          },
          { id: 4,
            brand: "AVEENO",
            name: "Positively Radiant Skin Brightening Facial Scrub",
            image: "https://storage.googleapis.com/a1aa/image/zTv4fAoVeqtGeok9n8Jk3H73gnTw6F2GvwW5XP7gP6jGfBJPB.jpg",
            reviewsCount: 358,
            price: 11.99
          },
          { id: 5,
            brand: "DOVE",
            name: "Body Wash with Pump with Skin Natural",
            image: "https://storage.googleapis.com/a1aa/image/Izoxbzte6qTnCqTHczGPte1utNJayvQ0yBosd3QP3DEfeBJPB.jpg",
            reviewsCount: 14,
            price: 19.93
          }
        ]
      };
    },
    computed: {
      filteredProducts() {
        return this.products.filter(product =>
          product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
    },
  };
  </script>
  
  <style scoped>
  /* Ajouter des styles personnalisés si nécessaire */
  
  /* La carte avec le volet au bas */
  .group:hover .absolute {
    transform: translateY(0); /* Lors du survol, le volet remonte */
    opacity: 1; /* Le volet devient visible au survol */
    cursor: pointer;
  }
  
  .group .absolute {
    transform: translateY(100%); /* Le volet est caché par défaut */
    opacity: 0; /* Volet invisible au départ */
  }
  
  .transition-all {
    transition: all 0.3s ease;
  }
  </style>
  
  