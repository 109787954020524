import { createStore } from 'vuex';

export default createStore({
  state: {
    products: [
      {
        id: '1',
        name: 'Produit Test 1',
        shortDescription: 'Description courte du produit 1.',
        longDescription: 'Ceci est la description longue du produit 1. Ce produit offre des fonctionnalités exceptionnelles pour satisfaire vos besoins.',
        price: 99.99,
        images: [
          'https://via.placeholder.com/600x400?text=Produit+1+Image+1',
          'https://via.placeholder.com/600x400?text=Produit+1+Image+2',
          'https://via.placeholder.com/600x400?text=Produit+1+Image+3',
        ],
        colorVariants: ['Rouge', 'Bleu', 'Vert'],
        additionalInfo: {
          dimensions: '20x20x20 cm',
          material: 'Acier inoxydable',
          weight: '1.2 kg',
        },
      },
      {
        id: '2',
        name: 'Produit Test 2',
        shortDescription: 'Description courte du produit 2.',
        longDescription: 'Ceci est la description longue du produit 2. Ce produit est conçu pour durer et répondre à vos attentes.',
        price: 49.99,
        images: [
          'https://via.placeholder.com/600x400?text=Produit+2+Image+1',
          'https://via.placeholder.com/600x400?text=Produit+2+Image+2',
        ],
        colorVariants: ['Noir', 'Blanc'],
        additionalInfo: {
          dimensions: '15x15x15 cm',
          material: 'Plastique',
          weight: '0.8 kg',
        },
      },
    ],
  },
  getters: {
    getProductById: (state) => (id) => {
      return state.products.find(product => product.id === id);
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
