import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login_Form from '@/views/Login_Form.vue'
import SearchResultsPage from '@/views/SearchResultsPage.vue'
import ProductDetailsPage from '@/views/ProductDetailsPage';
import Cart_Details from '@/views/Cart_Details.vue';
import Customer_Account from '@/views/Customer_Account.vue';
import error from '@/views/Error_Page.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/404',
    name: 'error',
    component: error
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart_Details
    },
    {
      path: '/customer_account',
      name: 'customer_account',
      component: Customer_Account
      },
  {
    path: '/product/:id', // Dynamique avec paramètre `id`
    name: 'product-details',
    component: ProductDetailsPage,
    props: true // Permet de passer le paramètre `id` en tant que prop
  },
  {
    path: '/search-results',
    name: 'search-results',
    component: SearchResultsPage, // Le composant des résultats de recherche
  },
  {
    path: '/login',
    name: 'login',
    component: Login_Form
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
