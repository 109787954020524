<template>
    <!-- Menu principal centré en dessous -->
    <div class="py-4">
      <div class="flex justify-center space-x-4 overflow-x-auto py-2 ">
    <button type="button" 
            class=" ml-12 px-4 py-2 text-sm font-medium text-gray-700 hover:text-white hover:bg-indigo-600 
            border border-gray-300 rounded-full transition-all duration-200">
        Femmes
    </button>
    <button type="button" 
            class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-white hover:bg-indigo-600 
            border border-gray-300 rounded-full transition-all duration-200">
        Hommes
    </button>
    <a href="#" 
       class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-white hover:bg-indigo-600 
       border border-gray-300 rounded-full transition-all duration-200">
        Entreprise
    </a>
    <a href="#" 
       class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-white hover:bg-indigo-600 
       border border-gray-300 rounded-full transition-all duration-200">
        Boutiques
    </a>
</div>

        </div>
        <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 mt-4">
    <div class="relative">
      <input
        v-model="searchQuery"
        @keyup.enter="handleSearch"
        type="text"
        placeholder="Recherchez vos produits..."
        class="w-full p-4 pl-10 rounded-l-full rounded-r-full border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <svg
        class="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 4a7 7 0 110 14 7 7 0 010-14zm0 0v1m0 8v1m0-4h1m-1 0h-1"
        ></path>
      </svg>
    </div>
  </div>

  <!-- Contenu principal -->
  <div class="mx-auto max-w-10xl px-4 sm:px-6 lg:px-8" :style="backgroundStyle">
    <main class="text-center mt-10"> <!-- Ajustez le mt-20 selon la hauteur de votre barre de navigation -->
      <!-- Section produits centrée -->
      <section class="mt-10 mb-8">
  <h2 class="text-black text-3xl font-bold">BON PLANS DU MOMENT</h2>
  <p class="text-black text-lg mb-8">Coup de foudre inévitable</p>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8 mx-auto px-4">
    <div 
      v-for="(product, index) in products_BonPlans.slice(0, 4)" 
      :key="index" 
      class="relative bg-white rounded-md shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl overflow-hidden">
      
      <!-- Image rectangulaire pour les produits -->
      <img 
        :src="product.image" 
        :alt="product.alt" 
        class="w-full h-60 object-cover" 
      />
      
      <span 
        v-if="product.isAd" 
        class="absolute top-2 right-2 bg-black text-white text-xs px-2 py-1 rounded-full">
        Publicité
      </span>
    </div>
  </div>
</section>

<div >
  <h2 class="text-3xl text-black font-bold text-center mb-8">Nos Promotions</h2> <!-- Titre ajouté ici -->
  <div class="flex space-x-4 overflow-x-auto overflow-hidden">
    <div v-for="(item, index) in Promotions" :key="index" class="relative bg-white rounded-md flex-none w-48 h-48 m-5 transform transition duration-300 hover:scale-105 cursor-pointer">
      <!-- Image ronde -->
      <img :alt="item.title" class="w-48 h-48 object-cover mb-4 mr-10 bg-gray-100 rounded-full" :src="item.image" />
      <div class="text-left">
        <span class="text-gray-800 text-xl">{{ item.price }}</span>
        <span class="text-gray-500 line-through ml-2">{{ item.originalPrice }}</span>
      </div>
      <h4 class="text-left text-l mb-1 text-gray-600 font-normal">{{ item.title }}</h4>
      <span class="text-left text-xs mb-2 text-gray-300 font-normal">{{ item.brand }}</span> 
    </div>
  </div>
</div>



<section class="mt-10 mb-8">
  <h2 class="text-black text-3xl font-bold">BON PLANS DU MOMENT</h2>
  <p class="text-black text-lg mb-8">Coup de foudre inévitable</p>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8 mx-auto px-4">
    <div 
      v-for="(product, index) in products_BonPlans.slice(5, 9)" 
      :key="index" 
      class="relative bg-white rounded-md shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl overflow-hidden">
      
      <!-- Image rectangulaire pour les produits -->
      <img 
        :src="product.image" 
        :alt="product.alt" 
        class="w-full h-60 object-cover" 
      />
      
      <span 
        v-if="product.isAd" 
        class="absolute top-2 right-2 bg-black text-white text-xs px-2 py-1 rounded-full">
        Publicité
      </span>
    </div>
  </div>
</section>

      
      <div class="container mx-auto py-12">
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
      products_BonPlans: [
        { image: 'https://www.bedsland.co.uk/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-02-at-11.49.05-AM.jpeg', alt: 'Verisure advertisement', isAd: true },
        { image: 'https://blog.bobochicparis.com/wp-content/uploads/2022/11/BF-HOME.jpg', alt: 'Maison 123 advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_1-1024x536.jpg', alt: 'El Ganso advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_10-1024x536.jpg', alt: 'Verisure advertisement', isAd: true },
        { image: 'https://www.bedsland.co.uk/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-02-at-11.49.05-AM.jpeg', alt: 'Verisure advertisement', isAd: true },
        { image: 'https://blog.bobochicparis.com/wp-content/uploads/2022/11/BF-HOME.jpg', alt: 'Maison 123 advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_1-1024x536.jpg', alt: 'El Ganso advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_10-1024x536.jpg', alt: 'Verisure advertisement', isAd: true },
      ],
      Promotions: [
        { image: 'https://www.bedsland.co.uk/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-02-at-11.49.05-AM.jpeg', alt: 'Verisure advertisement', isAd: true },
        { image: 'https://blog.bobochicparis.com/wp-content/uploads/2022/11/BF-HOME.jpg', alt: 'Maison 123 advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_1-1024x536.jpg', alt: 'El Ganso advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_10-1024x536.jpg', alt: 'Verisure advertisement', isAd: true },
        { image: 'https://www.bedsland.co.uk/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-02-at-11.49.05-AM.jpeg', alt: 'Verisure advertisement', isAd: true },
        { image: 'https://blog.bobochicparis.com/wp-content/uploads/2022/11/BF-HOME.jpg', alt: 'Maison 123 advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_1-1024x536.jpg', alt: 'El Ganso advertisement', isAd: false },
        { image: 'https://www.sealy.com.au/wp-content/uploads/2021/11/202111_Black-Friday-Assets_Smart-Display-1.91-1_10-1024x536.jpg', alt: 'Verisure advertisement', isAd: true },
      ],
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    },
  },
  methods: {
    handleSearch() {
      if (this.searchQuery) {
        this.$router.push({ name: 'search-results', query: { query: this.searchQuery } });
      }
    },
    toggleMenu() {
      // Menu toggle logic
    },
    setActiveCategory(category) {
      this.categories.forEach((cat) => (cat.active = false));
      category.active = true;
    },
  },
};
</script>

<style>
body {
  font-family: 'Roboto', sans-serif;
}
</style>
