<template>
    <div class="p-8 mt-20 min-h-screen font-roboto">
      <!-- Section principale du produit -->
      <div v-if="product">
    
        <div class="flex justify-center items-center">
  <div class="bg-white rounded-lg p-0 flex flex-col md:flex-row max-w-7xl w-full">
    <!-- Left Section: Thumbnails -->

    <!-- Main Image -->
    <div class="flex-1 mx-6 md:mx-12 mt-6 md:mt-0">
      <img
        :src="mainImage.src"
        :alt="mainImage.alt"
        class="w-full max-h-[500px] rounded-lg mx-auto object-cover"
      />
    </div>

    <div class="flex sm:flex-row thumbnails-container md:flex-col lg:flex-col lg:block md:block lg:space-y-4 md:space-y-4 mt-4 md:mt-0 lg:w-1/7 md:w-1/7">
      <img
        v-for="(thumbnail, index) in thumbnails"
        :key="index"
        :src="thumbnail.src"
        :alt="thumbnail.alt"
        :class="thumbnail.selected ? 'w-20 h-20 rounded-lg border-2 border-blue-500' : 'w-20 h-20 rounded-lg'"
        width="100"
        height="100"
      />
    </div>

    <!-- Right Section: Product Details -->
    <div class="w-full md:w-96 md:ml-12 mt-6 md:mt-0">
      <h1 class="text-2xl font-semibold">{{ product.name }}</h1>
      <p class="text-gray-500">{{ product.description }}</p>
      <div class="flex items-center mt-4">
        <span class="text-2xl font-bold">${{ product.price }}</span>
        <span v-if="product.originalPrice" class="text-gray-400 line-through ml-2">${{ product.originalPrice }}</span>
        <span v-if="product.discount" class="text-blue-500 ml-2">{{ product.discount }} off</span>
      </div>
      <div class="flex items-center mt-2">
        <span class="text-yellow-500">
          <i class="fas fa-star"></i> {{ product.rating }}
        </span>
       
      </div>

      <!-- Collapsible Sections -->
      <div class="mt-6">
  <div
    v-for="(detail, index) in details"
    :key="index"
    class="flex flex-col py-2 border-b"
  >
    <!-- Collapsible Header -->
    <div @click="toggleDetail(index)" class="flex justify-between items-center cursor-pointer">
      <span>{{ detail.title }}</span>
      <span class="text-gray-400">{{ detail.isOpen ? '-' : '+' }}</span>
    </div>

    <!-- Collapsible Content -->
    <div v-if="detail.isOpen" class="mt-2 text-gray-600">
      <p>{{ detail.description }}</p>
    </div>
  </div>
</div>


      <!-- Size Selection -->
   <!--  <div class="mt-6">
        <button class="w-full py-3 border rounded-lg text-gray-500">
          Choose Your Size
          <i class="fas fa-chevron-down"></i>
        </button>
      </div>
      --> 

      <!-- Action Buttons -->
      <div class="flex mt-6 space-x-4">
        <button class="flex-1 py-3 bg-blue-100 text-blue-500 rounded-lg">Ajouter au panier</button>
        <button class="flex-1 py-3 bg-blue-500 text-white rounded-lg"> <i class="far fa-heart"></i></button>
      </div>
    </div>
  </div>
</div>



  <!-- Onglets Description et Informations supplémentaires -->
  <div class="mt-12">
    <div class="flex border-b border-gray-200">
      <button
        class="py-2 px-4"
        :class="activeTab === 'description' ? 'text-gray-800 border-b-2 border-gray-800' : 'text-gray-600'"
        @click="setActiveTab('description')"
      >
        Description
      </button>
      <button
        class="py-2 px-4"
        :class="activeTab === 'additional' ? 'text-gray-800 border-b-2 border-gray-800' : 'text-gray-600'"
        @click="setActiveTab('additional')"
      >
        Informations Supplémentaires
      </button>
      <button
        class="py-2 px-4"
        :class="activeTab === 'Avis' ? 'text-gray-800 border-b-2 border-gray-800' : 'text-gray-600'"
        @click="setActiveTab('Avis')"
      >
        Avis
      </button>
    </div>

    <!-- Contenu des onglets -->
    <div v-if="activeTab === 'description'" class="mt-8">
      <h2 class="text-xl font-semibold">Description du Produit</h2>
      <p class="text-gray-600 mt-4">{{ product.longDescription }}</p>
    </div>

    <div v-if="activeTab === 'additional'" class="mt-8">
      <h2 class="text-xl font-semibold">Informations Supplémentaires</h2>
      <ul class="list-disc list-inside text-gray-600 mt-4">
        <li>Dimensions : {{ product.dimensions }}</li>
        <li>Matériau : {{ product.material }}</li>
        <li>Couleur : {{ product.color }}</li>
      </ul>
    </div>

    <div v-if="activeTab === 'Avis'" class="mt-8">
      <section class="py-24 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-6 mx-auto">
          <div class="w-full">
            <h2 class="font-manrope font-bold text-4xl text-black mb-8 text-center">
              Avis de nos clients
            </h2>
            <div class="grid grid-cols-1 xl:grid-cols-2 gap-11 pb-11 border-b border-gray-100 max-xl:max-w-2xl max-xl:mx-auto">
              <div v-for="(item, index) in ratings" :key="index" class="box flex flex-col gap-y-4 w-full">
                <div class="flex items-center w-full">
                  <p class="font-medium text-lg text-black mr-0.5">{{ item.rating }}</p>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12042_8589)">
                      <path
                        d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                        fill="#FBBF24"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12042_8589">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="h-2 w-full sm:min-w-[278px] rounded-3xl bg-amber-50 ml-5 mr-3">
                    <span
                      class="h-full rounded-3xl bg-amber-400 flex"
                      :style="{ width: item.percentage + '%' }"
                    ></span>
                  </p>
                  <p class="font-medium text-lg text-black mr-0.5">{{ item.total }}</p>
                </div>
              </div>
            </div>
            <div>
              <div 
                v-for="(review, index) in reviews" 
                :key="index" 
                class="pt-11 pb-8 border-b border-gray-100 max-xl:max-w-2xl max-xl:mx-auto"
              >
                <div class="text-xl text-black font-semibold">{{ review.username }}</div>
                <div class="text-sm text-gray-500 mt-2">{{ review.comment }}</div>
                <div class="flex justify-start items-center gap-2 mt-2">
                  <span class="text-sm text-gray-600">Rating:</span>
                  <div class="flex">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

  
      <!-- Message de produit non trouvé -->
      <div v-else>
        <p>Produit non trouvé.</p>
      </div>
      <div class="bg-white mt-20 p-8 ">
          <div class="mx-auto max-w-2xl px-4 py-8 lg:max-w-7xl lg:px-8">
          
      
            <!-- Conteneur Flex avec overflow horizontal -->
            <div class="mt-6 flex overflow-x-auto gap-6 pb-4">
              <section class="py-24">
        <div class="mx-auto max-w-7xl border-t py-4 px-4 sm:px-6 lg:px-8">
            <h2 class="font-manrope font-bold text-4xl text-black mb-8 max-lg:text-center ">
              Produits similaires
            </h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                <a href="javascript:;"
                    class="mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                        <img src="https://pagedone.io/asset/uploads/1700726158.png" alt="face cream image"
                            class="w-full aspect-square rounded-2xl object-cover">
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6
                                class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">
                                Face cream</h6>
                            <h6 class="font-semibold text-xl leading-8 text-indigo-600">$100</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">Orange & Aloe Vera</p>
                    </div>
                </a>

                <a href="javascript:;"
                    class="mx-auto sm:ml-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                        <img src="https://pagedone.io/asset/uploads/1700726174.png" alt="plastic bottle image"
                            class="w-full aspect-square rounded-2xl object-cover">
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6
                                class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">
                                Plstic bottle</h6>
                            <h6 class="font-semibold text-xl leading-8 text-indigo-600">$40</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">Black color</p>
                    </div>
                </a>

                <a href="javascript:;"
                    class="mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                        <img src="https://pagedone.io/asset/uploads/1700726191.png" alt="cream image"
                            class="w-full aspect-square rounded-2xl object-cover">
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6
                                class="font-semibold text-xl leading-8 text-black  transition-all duration-500 group-hover:text-indigo-600">
                                Men cream</h6>
                            <h6 class="font-semibold text-xl leading-8 text-indigo-600">$100</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">Aloe Vera and Neem</p>
                    </div>
                </a>

                <a href="javascript:;"
                    class="mx-auto sm:ml-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                        <img src="https://pagedone.io/asset/uploads/1700726207.png" alt="perfume bottle image"
                            class="w-full aspect-square rounded-2xl object-cover">
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6
                                class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">
                                Cold Perfume</h6>
                            <h6 class="font-semibold text-xl leading-8 text-indigo-600">$100</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">White perfume</p>
                    </div>
                </a>
            </div>
        </div>
    </section>
                                            
            </div>
          </div>
        </div>
    </div>
  </template>
  
  
  <script>
  export default {
    name: 'ProductDetailsPage',
    data() {
      return {
        thumbnails: [
        {
          src: "https://storage.googleapis.com/a1aa/image/JWeS1sZdik0kXSmJ80bHsiepAUOUNkGZnEXTjnUQG8DWBazTA.jpg",
          alt: "Thumbnail of brown shoe with yellow laces",
          selected: false,
        },
        {
          src: "https://storage.googleapis.com/a1aa/image/ntRBe59hepo2nUS0eiBaP0taIWQOfCxjKwhRgTXv3hOiFoNPB.jpg",
          alt: "Thumbnail of brown shoe with yellow laces side view",
          selected: false,
        },
        {
          src: "https://storage.googleapis.com/a1aa/image/oXeLIZiNqQxcSKGcwe6fP6FNw2f8FxDyyYpr6ms3tdkVFoNPB.jpg",
          alt: "Thumbnail of brown shoe soles",
          selected: false,
        },
        {
          src: "https://storage.googleapis.com/a1aa/image/rZ9L7j5VsmYMBl693ur20R4TIbGDYv3rCNRHJ1JTwkhWg28E.jpg",
          alt: "Thumbnail of brown shoe with yellow laces selected",
          selected: true,
        },
        {
          src: "https://storage.googleapis.com/a1aa/image/OrPbeDeqghpLFkQ5YNJWoDfSZGBfEpQnMmLaIDvCMLHvFoNPB.jpg",
          alt: "Thumbnail of pair of brown shoes with yellow laces",
          selected: false,
        },
      ],
      mainImage: {
        src: "https://storage.googleapis.com/a1aa/image/wSOsHAb2xnLKEV1DlNLEOOL5eIWbqRp5xcXzIPMGRfzTBazTA.jpg",
        alt: "Main image of brown shoe with yellow laces",
      },
      details: [
        { title: "Detail 1", description: "Description for detail 1", isOpen: false },
        { title: "Detail 2", description: "Description for detail 2", isOpen: false },
        { title: "Detail 3", description: "Description for detail 3", isOpen: false },
      ],
        product: {
          id: '1',
          name: 'Produit Test',
          description: 'Ceci est une  du produit. Il est très utile pour démontrer la page produit.Ceci est une description du produit. Il est très utile pour démontrer la page produit.',
          price: 99.99,
          image: 'https://via.placeholder.com/600x400?text=Image+du+produit',
          longDescription: 'Voici une description détaillée du produit...',
          dimensions: '20x10x5 cm',
          material: 'Plastique',
          color: 'Rouge',
          images: ['https://via.placeholder.com/600x400?text=Image+1', 'https://via.placeholder.com/600x400?text=Image+2'],
        },
        similarProducts: [
          { id: '2', name: 'Produit Similaire 1', price: 49.99, image: 'https://via.placeholder.com/200x150?text=Produit+1', color: 'Bleu' },
          { id: '3', name: 'Produit Similaire 2', price: 39.99, image: 'https://via.placeholder.com/200x150?text=Produit+2', color: 'Vert' },
          { id: '4', name: 'Produit Similaire 3', price: 59.99, image: 'https://via.placeholder.com/200x150?text=Produit+3', color: 'Jaune' },
        ],
        ratings: [
        { rating: 5, percentage: 30, total: 989 },
        { rating: 4, percentage: 40, total: 4500 },
        { rating: 3, percentage: 20, total: 50 },
        { rating: 2, percentage: 16, total: 16 },
        { rating: 1, percentage: 10, total: 8 },
      ],
        reviews: [
        {
          stars: 5,
          title: "Outstanding Experience!!!",
          name: "John Doe",
          date: "November 22, 2024",
          image: 'https://via.placeholder.com/100',
          content: "This service exceeded my expectations. Highly recommend it!",
        },

        {
          stars: 4,
          title: "Great Service",
          name: "Jane Smith",
          date: "November 20, 2024",
          image: 'https://via.placeholder.com/100',
          content: "Very professional and efficient. Will use it again!",
        },
        // Add more reviews here...
      ],
        selectedImage: '',
        activeTab: 'description',
        quantity: 1, // Quantité du produit sélectionné
        cartCount: 0, // Nombre d'articles dans le panier
      };
    },
    methods: {
      changeImage(image) {
        this.selectedImage = image;
      },
      toggleDetail(index) {
      // Inverse l'état d'ouverture de l'élément cliqué
      this.details[index].isOpen = !this.details[index].isOpen;
    },
      goBack() {
      // Navigate back in the browser history
      this.$router.go(-1); // Or use window.history.back() if you're not using Vue Router
    },
      setActiveTab(tab) {
        this.activeTab = tab;
      },
      addToCart(product, quantity) {
        this.cartCount += quantity; // Mise à jour du nombre d'articles dans le panier
        alert(`${product.name} ajouté au panier.`);
      },
      addToWishlist(product) {
        alert(`${product.name} ajouté à la wishlist.`);
      },
      selectColor(color) {
        this.product.color = color; // Mise à jour de la couleur sélectionnée
      },
      increaseQuantity() {
        this.quantity += 1;
      },
      decreaseQuantity() {
        if (this.quantity > 1) this.quantity -= 1;
      },
    
    },
  };
  </script>
  
  <style>
  @media (max-width: 768px) {
  .thumbnails-container {
    display: inline-flex;
    overflow: auto;
    gap: 10px
  }
}
</style>
  
