<template>
    <div class="">
      <div class="max-w-6xl mx-auto p-6">
        <h1 class="text-2xl font-bold mb-4">Votre Panier</h1>
        <div class="flex justify-between items-center mb-4">
          <span class="text-gray-500">{{ itemsInCart }} Article(s) dans le panier</span>
        </div>
  
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div class="md:col-span-2">
            <div v-for="(item, index) in cartItems" :key="index" class="bg-white p-4 mb-4 border-b border-gray-300">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <img :alt="item.name" :src="item.image" class="w-16 h-16 rounded-lg" />
                  <div class="ml-4">
                    <h2 class="text-lg font-semibold">{{ item.name }}</h2>
                    <p class="text-gray-500">{{ item.color }}</p>
                  </div>
                </div>
                <div class="flex items-center space-x-4">
                  <select v-model="item.quantity" class="border border-gray-300 rounded-lg p-2">
                    <option v-for="q in item.availableQuantities" :key="q" :value="q">{{ q }}</option>
                  </select>
                  <span class="text-lg font-semibold">${{ item.price }}</span>
                  <button @click="removeItem(index)" class="ml-4 text-gray-500">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              
            </div>
          </div>
  
          <div class="bg-gray-50 p-6 rounded-lg ">
            <div class="mb-4">
              <div class="flex justify-between mb-2 py-5 border-b">
                <span class="text-gray-500">Sous-total</span>
                <span class="text-lg font-semibold">${{ subtotal }}</span>
              </div>
              <div class="flex justify-between mb-2 py-5 border-b">
                <span class="text-gray-500">TVA</span>
                <span class="text-lg font-semibold">${{ tax }}</span>
              </div>
              <div class="flex justify-between mb-2 py-5 border-b">
                <span class="text-gray-500">Expédition</span>
                <span class="text-lg font-semibold">Calculé au Payment</span>
              </div>
              <div class="flex justify-between mb-4 py-5">
                <span class="text-lg font-semibold">Total</span>
                <span class="text-lg font-semibold">${{ total }}</span>
              </div>
            </div>
            <button class="w-full bg-black text-white py-3 rounded-lg mb-4">Confirmer le paiement</button>
            <button class="w-full border border-gray-300 text-gray-700 py-3 rounded-lg">Continuer les achats</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        itemsInCart: 4,
        cartItems: [
          {
            name: 'Apple Watch Series 7 – 44mm',
            color: 'Golden',
            price: 259.00,
            image: 'https://storage.googleapis.com/a1aa/image/JGha9hX0ZxaVOdvS50kb2E3ZNlwX5hQgwbKtM4XMqmOPwo8E.jpg',
            quantity: 1,
            availableQuantities: [1, 2, 3, 4],
            taxRate: 20, // Taux de TVA en pourcentage
            taxIncluded: false, // TVA non incluse dans le prix
          },
          {
            name: 'Beylob 90 Speaker',
            color: 'Space Gray',
            price: 99.00,
            image: 'https://storage.googleapis.com/a1aa/image/WRfxOimqvhQxaCOHBb1s685esQrObsQLvrX7EH0VdNgeBGlnA.jpg',
            quantity: 1,
            availableQuantities: [1, 2, 3, 4],
            taxRate: 10, // Taux de TVA en pourcentage
            taxIncluded: true, // TVA incluse dans le prix
          },
          {
            name: 'Beoplay M5 Bluetooth Speaker',
            color: 'Silver Collection',
            price: 129.00,
            image: 'https://storage.googleapis.com/a1aa/image/vTPBHidPOkqwL9be2XDFfJI9vlmTIzgfXLImHPGWLfRuDMKPB.jpg',
            quantity: 1,
            availableQuantities: [1, 2, 3, 4],
            taxRate: 20,
            taxIncluded: false,
          },
          {
            name: 'Apple Watch Series 7 – 44mm',
            color: 'Golden',
            price: 379.00,
            image: 'https://storage.googleapis.com/a1aa/image/JGha9hX0ZxaVOdvS50kb2E3ZNlwX5hQgwbKtM4XMqmOPwo8E.jpg',
            quantity: 1,
            availableQuantities: [1, 2, 3, 4],
            taxRate: 20,
            taxIncluded: true,
          },
        ],
        subtotal: 589.00,
        tax: 0.00,
        shipping: 10.00,
        total: 599.00,
      };
    },
    methods: {
      removeItem(index) {
        this.cartItems.splice(index, 1);
        this.updateTotals();
      },
      updateTotals() {
        let totalTax = 0;
        this.subtotal = this.cartItems.reduce((sum, item) => {
          let itemTotal = item.price * item.quantity;
          if (!item.taxIncluded) {
            totalTax += (itemTotal * item.taxRate) / 100; // Calcul TVA si non incluse
          }
          return sum + itemTotal;
        }, 0);
  
        this.tax = totalTax;
        this.total = this.subtotal + this.tax + this.shipping;
      },
    },
    mounted() {
      this.updateTotals(); // Mise à jour des totaux au montage
    },
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles supplémentaires ici */
  </style>
  