<template>
  <div class="bg-white">

  <header class="relative bg-white">
    <p class="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8 xl:px-10 text-xs sm:text-sm md:text-base lg:text-lg">
    Livraison gratuite pour les commandes de plus de 100€
</p>


<nav aria-label="Top" class="mx-auto max-w-10xl px-4 sm:px-6 lg:px-8">
    <div class="border-b border-gray-200 py-4">
        <div class="flex items-center justify-between w-full">
            <!-- Bouton Menu et Hamburger -->
            <div @click="toggleMenu" class="flex items-center sm:hidden">
                <!-- Hamburger Icone -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
                </svg>
              
            </div>

            <div @click="toggleMenu"  class="text-gray-700 font-medium text-sm mr-4">
        Menu
    </div>

             <!-- Texte Menu -->
  

            <!-- Volet latéral (Menu) -->
            <div v-show="isMenuOpen" class="fixed inset-0 bg-gray-900 bg-opacity-50 z-40" @click="toggleMenu">
                <div :class="{'transform translate-x-full': !isMenuOpen, 'transform translate-x-0': isMenuOpen}" class="fixed left-0 top-0 bottom-0 w-96 bg-white transition-transform duration-300 ease-in-out z-50">
                    <div class="max-h-screen overflow-y-auto relative">
                        <button @click="this.isMenuOpen == false" class="absolute top-4 left-4 text-4xl text-gray-400">&times;</button>
                        <div class="border-b">
                            <div class="flex justify-between pb-4 p-6 pt-20 px-12">
                                <div class="text-lg font-semibold">Salon</div>
                                <div class="text-lg font-semibold">Chambre</div>
                            </div>
                        </div>
                        <div class="mt-4 mb-4 border-b font-bold text-gray-700">
                            <ul>
                                <li v-for="(category, index) in menuItems" :key="index" class="py-2 border-gray-300 mb-4">
                                    <div class="flex flex-col items-center">
                                        <img :src="category.image" :alt="category.name" class="w-28 h-28 object-cover rounded-lg mb-2" />
                                        <div>{{ category.name }}</div>
                                    </div>
                                    <div v-for="(subCategory, subIndex) in category.subCategories" :key="subIndex" class="mt-4">
                                        <a :href="subCategory.link" class="text-gray-700 font-normal py-2">{{ subCategory.name }}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div v-if="isLoggedIn" class=" items-center space-x-2 relative">
                          <div class="mt-4 mb-2 font-semibold text-gray-700">
                            Bonjours {{ userName }}
                        </div>
                          <div class="mt-4">
                            <a href="/customer_account" class="text-gray-700 py-2"> Mon Compte</a>
                        </div>
                        <div class="mt-4">
                            <a href="/customer_account" class="text-gray-700 py-2"> Ma Wishlist</a>
                        </div>
                        <div class="mt-4">
                            <a href="/customer_account" class="text-gray-700 py-2"> Mes Commandes</a>
                        </div>
                        </div>

                        <div v-else class="flex items-center space-x-2 hidden lg:flex">
                          <div class="mt-4">
                            <a href="#" class="text-gray-700 py-2">Se connecter</a>
                        </div>
                        <div class="mt-4 mb-2 font-semibold text-gray-700">
                            Vous êtes un professionnel ?
                        </div>
                </div>
                        
                    </div>
                </div>
            </div>

            <!-- Logo -->
            <div class="absolute left-1/2 transform -translate-x-1/2">
                <a href="/">
                    <span class="sr-only">MONBOLI</span>
                    <img class="h-12 w-auto" src="./assets/logo.svg" alt="Logo">
                </a>
            </div>

            <!-- Menu pour les grands écrans -->
            <div class="flex items-center space-x-6 ml-auto">
                <!-- Si l'utilisateur est connecté -->
                <div v-if="isLoggedIn" class="flex items-center space-x-2 relative hidden sm:flex">
    <!-- Bouton dropdown -->
    <button @click="toggleDropdown" type="button" class="inline-flex items-center rounded-lg justify-center p-2 hover:bg-gray-100 text-sm font-medium text-gray-900">
        <svg class="w-5 h-5 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-width="2" d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
        </svg>              
        {{ userName }}
        <svg class="w-4 h-4 ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7"/>
        </svg> 
    </button>
    
    <!-- Dropdown Menu -->
    <div v-show="dropdownVisible" class="dropdown-menu absolute top-full left-0 z-10 w-56 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700">
        <ul class="p-2 text-start text-sm font-medium text-gray-900 dark:text-white">
            <li><a href="/customer_account" class="inline-flex w-full items-center gap-2 px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600"> Mon Compte </a></li>
            <li><a href="/customer_account" class="inline-flex w-full items-center gap-2 px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600"> Mes Commandes  </a></li>
            <li><a href="/customer_account" class="inline-flex w-full items-center gap-2 px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600"> Ma Wishlist </a></li>
        </ul>
    </div>
</div>



                <!-- Si l'utilisateur n'est pas connecté -->
                <div v-else class="flex items-center space-x-2 hidden lg:flex">
                    <a href="/login" class="text-sm font-medium text-gray-700 hover:text-gray-800">Espace Client</a>
                    <span class="h-6 w-px bg-gray-200" aria-hidden="true"></span>
                    <a href="#" class="text-sm font-medium text-gray-700 hover:text-gray-800">Espace Professionnel</a>
                </div>

                <div class="flex "> <!-- Réduit l'écart entre les éléments -->
    <!-- Loupe -->
    <a @click="toggleSearch" href="#" class="p-2 text-gray-400 hover:text-gray-500">
        <span class="sr-only">Rechercher</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
    </a>

    <!-- Panier -->
    <a @click="toggleCart" href="#" class="group flex items-center p-2 space-x-2">
        <svg class="h-6 w-6 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM15.75 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"/>
        </svg>
        <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{ cartItems.length }}</span>
    </a>
</div>

            </div>
        </div>
        <div v-if="showSearch" class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 mt-4 transition-all duration-500 ease-in-out transform">
    <div class="relative">
        <input
            v-model="searchQuery"
            @keyup.enter="handleSearch"
            type="text"
            placeholder="Recherchez vos produits..."
            class="w-full py-2 sm:py-3 pl-10 rounded-l-full rounded-r-full border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base"
        />
        <svg
            class="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 4a7 7 0 110 14 7 7 0 010-14zm0 0v1m0 8v1m0-4h1m-1 0h-1"
            ></path>
        </svg>
    </div>
</div>

    </div>
</nav>

</header>
<div v-show="isCartOpen" class="fixed inset-0 bg-black bg-opacity-50 z-50" @click="toggleCart"></div>
<div v-show="isCartOpen" class="fixed right-0 top-0 bottom-0 w-96 bg-white z-50 transform transition-transform duration-300 ease-in-out" :class="{'translate-x-full': !isCartOpen, 'translate-x-0': isCartOpen}">
  <div class="p-4 flex items-center justify-between">
    <h2 class="text-xl font-semibold">Votre Panier</h2>
    <button @click="toggleCart" class="text-gray-600 hover:text-gray-900">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>

  <!-- Contenu du panier -->
  <div class="p-4 overflow-y-auto h-[calc(100%-200px)]">
    <ul>
      <li v-for="(item, index) in cartItems" :key="index" class="flex items-center mb-4">
        <!-- Image du produit -->
        <img :src="item.image" alt="Image produit" class="w-16 h-16 object-cover rounded-md mr-4" />

        <!-- Détails produit -->
        <div class="flex-1">
          <p class="font-medium">{{ item.name }}</p>
          <p class="text-sm text-gray-500">Quantité : {{ item.quantity }}</p>
        </div>

        <!-- Prix et bouton corbeille -->
        <div class="flex items-center">
          <p class="text-gray-700 font-semibold mr-4">{{ item.price }} €</p>
          <button @click="removeItem(index)" class="text-red-600 hover:text-red-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </li>
    </ul>

    <p v-if="cartItems.length === 0" class="text-center text-gray-500 mt-4">Votre panier est vide.</p>
  </div>

  <!-- Total du panier -->


  <!-- Boutons d'action -->
  <div class="p-4  fixed bottom-0 w-96 bg-white">
    <div class="p-4 mb-4 border-b">
  <div class="flex justify-between items-center">
    <span class="text-lg font-semibold">Total :</span>
    <span class="text-lg font-semibold text-gray-800">{{ cartTotal }} €</span>
  </div>
</div>
    <div class="flex  flex-col gap-4">
      <button @click="goToCart" class="px-4 py-2 bg-indigo-600 text-white rounded-md">Accéder au panier</button>
      <button @click="continueShopping" class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md">Continuer les achats</button>
    </div>
  </div>
</div>





</div>

  <router-view/>

  <footer class="bg-gray-900">
  <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
    <!-- Section Logo avec description -->
    <div class="text-center mb-12">
      <img
        alt="Logo de la marketplace"
        class="w-64 h-12 rounded-full filter invert mx-auto"
        src="./assets/logo.svg"
      />
      <p class="text-white mt-2 text-lg">
        Marketplace de vente de meubles - Découvrez des meubles uniques pour votre intérieur.
      </p>
    </div>

    <!-- Section de liens (grille responsive) -->
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 mt-12">
      <!-- Liens Plateforme -->
      <div>
        <h3 class="text-gray-500 uppercase tracking-wider text-sm font-medium text-white">
          Plateforme
        </h3>
        <ul class="mt-4 space-y-4">
          <li><a class="text-white hover:text-gray-400" href="#">À propos</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Fonctionnalités</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Tarifs & Plans</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Contact</a></li>
        </ul>
      </div>

         <!-- Liens Plateforme -->
         <div>
        <h3 class="text-gray-500 uppercase tracking-wider text-sm font-medium text-white">
          Plateforme
        </h3>
        <ul class="mt-4 space-y-4">
          <li><a class="text-white hover:text-gray-400" href="#">À propos</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Fonctionnalités</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Tarifs & Plans</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Contact</a></li>
        </ul>
      </div>

      <!-- Liens Ressources -->
      <div>
        <h3 class="text-gray-500 uppercase tracking-wider text-sm font-medium text-white">
          Ressources
        </h3>
        <ul class="mt-4 space-y-4">
          <li><a class="text-white hover:text-gray-400" href="#">Compte</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Outils</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Newsletter</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">FAQ</a></li>
        </ul>
      </div>

      <!-- Liens Produits -->
      <div>
        <h3 class="text-gray-500 uppercase tracking-wider text-sm font-medium text-white">
          Produits
        </h3>
        <ul class="mt-4 space-y-4">
          <li><a class="text-white hover:text-gray-400" href="#">Ventes Rapides</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Objets Rares</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Meubles Modernes</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Décoration</a></li>
        </ul>
      </div>

      <!-- Liens Légal -->
      <div>
        <h3 class="text-gray-500 uppercase tracking-wider text-sm font-medium text-white">
          Légal
        </h3>
        <ul class="mt-4 space-y-4">
          <li><a class="text-white hover:text-gray-400" href="#">Conditions Générales</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Politique de Confidentialité</a></li>
          <li><a class="text-white hover:text-gray-400" href="#">Licences</a></li>
        </ul>
      </div>
    </div>

    <!-- Section Copyright -->
    <div class="mt-12 border-t border-gray-800 pt-8">
      <p class="text-gray-500 text-center text-sm">
        © 2024, Tous droits réservés par Marketplace. Votre destination pour acheter des meubles uniques et élégants.
      </p>
    </div>
  </div>
</footer>



</template>


<script>
export default {
  data() {
    return {
      // Utilisateur connecté
      isLoggedIn: true, // change ça selon ton état de connexion réel
      userName: 'John Doe', // Nom de l'utilisateur
      // Contrôle de la visibilité du dropdown
      dropdownVisible: false,
      showSearch: false,
      searchQuery: '',
      isCartOpen: false,
      isMenuOpen: false,
      cartItems: [
      { name: 'Produit 1', price: 29.99, quantity: 2, image: 'https://via.placeholder.com/100' },
      { name: 'Produit 2', price: 15.50, quantity: 1, image: 'https://via.placeholder.com/100' },
      { name: 'Produit 3', price: 42.00, quantity: 3, image: 'https://via.placeholder.com/100' },
    ],
    menuItems: [
        {
          name: 'Canapés & Fauteuils',
          image: 'https://via.placeholder.com/200',
          subCategories: [
            { name: 'Canapés en tissu', link: '#' },
            { name: 'Canapés en cuir', link: '#' },
            { name: 'Fauteuils design', link: '#' },
            { name: 'Canapés modulables', link: '#' }
          ]
        },
        {
          name: 'Tables & Chaises',
          image: 'https://via.placeholder.com/200',
          subCategories: [
            { name: 'Tables à manger', link: '#' },
            { name: 'Chaises de salle à manger', link: '#' },
            { name: 'Chaises de bureau', link: '#' },
            { name: 'Tables basses', link: '#' }
          ]
        },
        {
          name: 'Rangements & Meubles TV',
          image: 'https://via.placeholder.com/200',
          subCategories: [
            { name: 'Meubles TV', link: '#' },
            { name: 'Bibliothèques', link: '#' },
            { name: 'Commodités et étagères', link: '#' },
            { name: 'Meubles de rangement', link: '#' }
          ]
        }
      ]
    };
  },
  computed: {
    cartTotal() {
    const total = this.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    console.log("Total du panier calculé :", total);
    return total;
  },
},
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleCart() {
      this.isCartOpen = !this.isCartOpen;
    },
    // Méthode pour afficher/masquer le dropdown
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    toggleSearch() {
                this.showSearch = !this.showSearch;
            },
  removeItem(index) {
      this.cartItems.splice(index, 1);
    },
    goToCart() {
  
  window.location.href = '/cart';  // Redirection vers la page panier
},
    continueShopping() {
      this.isCartOpen = false;
  
    },
  }
};
</script>


<style>

.relative {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Positionner sous le bouton */
  left: 50%; /* Centrer horizontalement */
  transform: translateX(-50%); /* Ajuster la position horizontale */
  z-index: 10;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
}


</style>

